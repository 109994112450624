.App-logo {
  font-weight: bold;
  font-size: 20px;
  color: #FFFFFF;
}

.intro-list li{
  list-style-type: '\2714' !important;
  padding-left: 15px;

}
.intro-list li::marker {
  color: blue;
}
.collect-data li{
  list-style-type: disc !important;

}
.keep-info li{
  list-style-type: disc !important;

}
.about-policy li{
  list-style-type: disc !important;
}
.delete-info li{
  list-style-type: disc !important;
}
.retain-info li{
  list-style-type: disc !important;
}